<template>
  <b-container fluid>
    <!-- modal lista items empleado -->
    <!-- :title="usuarioSeleccionado != null ? 'Liquidación de ' + usuarioSeleccionado.persona.nombres + ' ' + usuarioSeleccionado.persona.apellidos : ''" -->
    <b-modal :title="'Liquidación'" id="miModalDetalle" size="xl" hide-footer>
      <div class="row px-5">
        <div class="col-md-12">
          <!-- {{nominaLiquidacionPersonaDetalle}} -->
          <div
            class="m-4 table-responsive"
            style="height: 50vh; overflow: scroll"
          >
            <table class="table">
              <thead>
                <tr>
                  <!-- <th scope="col">conceptoId</th> -->
                  <th scope="col">Fecha inicio</th>
                  <th scope="col">Fecha fin</th>
                  <th scope="col">Concepto</th>
                  <th scope="col">base liquida</th>
                  <th scope="col">porcentaje trabajador</th>
                  <th scope="col">valor trabajador</th>
                  <th scope="col">porcentaje empleador</th>
                  <th scope="col">valor empleador</th>
                  <th scope="col">Devengado</th>
                  <th scope="col">Deducción</th>
                  <!-- <th scope="col">Opciones</th> -->
                </tr>
              </thead>
              <tbody
                v-for="itemTable of nominaLiquidacionPersonaDetalle"
                :key="itemTable.id"
              >
                <tr>
                  <!-- <th scope="row">{{ itemTable.conceptoId }}</th> -->
                  <td>
                    {{
                      itemTable.fechaInicio != null
                        ? $moment(itemTable.fechaInicio).format(
                            "DD-MM-YYYY HH:mm"
                          )
                        : "--"
                    }}
                  </td>
                  <td>
                    {{
                      itemTable.fechaFin != null
                        ? $moment(itemTable.fechaFin).format("DD-MM-YYYY HH:mm")
                        : "--"
                    }}
                  </td>

                  <td>{{ itemTable.concepto }}</td>
                  <td>
                    {{
                      Number(itemTable.baseLiquida).toLocaleString("es-CO", {
                        style: "currency",
                        currency: "COP",
                      })
                    }}
                  </td>
                  <td>
                    {{
                      Number(itemTable.porcentajeTrabajador).toLocaleString(
                        "es"
                      )
                    }}
                  </td>
                  <td>
                    {{
                      Number(itemTable.valorTrabajador).toLocaleString(
                        "es-CO",
                        {
                          style: "currency",
                          currency: "COP",
                        }
                      )
                    }}
                  </td>
                  <td>
                    {{
                      Number(itemTable.porcentajeEmpleador).toLocaleString("es")
                    }}
                  </td>
                  <td>
                    {{
                      Number(itemTable.valorEmpleador).toLocaleString("es-CO", {
                        style: "currency",
                        currency: "COP",
                      })
                    }}
                  </td>
                  <td>
                    {{
                      itemTable.tDev
                        ? Number(itemTable.tDev).toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        : 0
                    }}
                  </td>
                  <td>
                    {{
                      itemTable.tDed
                        ? Number(itemTable.tDed).toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        : 0
                    }}
                  </td>
                  <td>
                    <!-- <i
                      v-if="!['Salario', 'Auxilio de transporte', , 'Salud', 'Fondo de pensión'].includes(itemTable.concepto)"
                      @click="eliminarItem(itemTable)"
                      class="fas fa-solid fa-trash px-1 text-danger"
                    ></i> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row mb-3">
            <div class="col 6">
              Devengados:
              {{
                nominaLiquidacionPersonaDetalle
                  .reduce((prev, curr) => prev + Number(curr.tDev), 0)
                  .toLocaleString("es-CO", {
                    style: "currency",
                    currency: "COP",
                  })
              }}
            </div>
            <div class="col 6">
              Deducciones:
              {{
                nominaLiquidacionPersonaDetalle
                  .reduce((prev, curr) => prev + Number(curr.tDed), 0)
                  .toLocaleString("es-CO", {
                    style: "currency",
                    currency: "COP",
                  })
              }}
            </div>
            <div class="col 6">
              Total:
              {{
                (
                  nominaLiquidacionPersonaDetalle.reduce(
                    (prev, curr) => prev + Number(curr.tDev),
                    0
                  ) -
                  nominaLiquidacionPersonaDetalle.reduce(
                    (prev, curr) => prev + Number(curr.tDed),
                    0
                  )
                ).toLocaleString("es-CO", {
                  style: "currency",
                  currency: "COP",
                })
              }}
            </div>
          </div>
          <div class="text-right px-5 pb-4">
            <!-- <button @click.prevent="guardarDataUsuario()" type="button" class="mx-1 btn px-4 py-2 btn-primary">Guardar</button>
              <button class="mx-1 btn px-4 py-2 btn-secondary" @click="$router.push('/helex/numeraciones')">Cancelar</button> -->
          </div>
        </div>
      </div>
      <!-- <template #modal-footer> -->
      <div class="w-100 text-right">
        <!-- <button :hidden="item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2" class="mx-1 btn px-4 py-2 btn-primary" @click="$bvModal.show('my-modalDevengados')">
          Devengados
        </button>
        <button :hidden="item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2" class="mx-1 btn px-4 py-2 btn-secondary" @click="$bvModal.show('my-modalDeducciones')">
          Deducciones
        </button> -->
        <button
          class="mx-1 btn px-4 py-2 btn-success"
          @click="
            nuevoItem = {};
            $bvModal.hide('miModalDetalle');
          "
        >
          Cerrar
        </button>
      </div>
      <!-- </template> -->
    </b-modal>

    <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">
          <card>
            <h4 class="px-4 py-4"><b>Nueva Liquidación nómina</b></h4>
            <template v-slot:headerTitle></template>
            <div class="row px-5 mb-4">
              <div class="col-md-6">
                <label for=" " class="form-label">
                  Período de Liquidación
                </label>
                <div>
                  <!-- :readonly="item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2" -->
                  <label for=" " class="form-label">Fecha inicio</label>
                  <input
                    readonly
                    required
                    v-model="item.fechaInicio"
                    placeholder=""
                    type="date"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mt-4">
                  <label for=" " class="form-label">Fecha fin</label>
                  <!-- :readonly="item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2" -->
                  <input
                    readonly
                    v-model="item.fechaFin"
                    required
                    placeholder=""
                    type="date"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mt-4">
                  <label for=" " class="form-label">Tipo de Liquidación</label>
                  <input
                    disabled
                    :value="
                      item.tipoLiquidacion == 1
                        ? 'Primer semestre'
                        : item.tipoLiquidacion == 2
                        ? 'Último semestre / Navideña'
                        : 'Nómina'
                    "
                    required
                    placeholder=""
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mt-4">
                  <label for=" " class="form-label">Estado</label>
                  <input
                    disabled
                    :value="'por liquidar'"
                    required
                    placeholder=""
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
            </div>
          </card>

          <!-- <template v-slot:headerTitle> </template> -->
          <div class="px-5">
            <div class="row">
              <div class="col-12">
                <CardTable :data="dataCards" />
              </div>
              <!-- <div class="col-12 col-md">
                  <BtnAddCardTable text="Abrir Ticket" url="AgregarTickets" />
                </div> -->
            </div>
          </div>

          <!-- empleados -->
          <card>
            <!-- <h4 class="px-4 py-4"><b>Empleados/as</b></h4>
            <p class="px-4">Gestiona la información de tus empleados/as que vas a tener en cuenta para liquidar la nómina de este período.</p> -->
            <div class="row">
              <div class="col-3 ml-5 mt-4 d-flex">
                <!-- <button @click.prevent="showModalEmpleados()" class="btn px-4 py-2 btn-primary">Agregar empleados</button> -->
                <vs-button @click.prevent="recalculaLiquida()"
                  >Recalcular liquidación</vs-button
                >
                <vs-button @click.prevent="liquidarNomina()" danger
                  >Liquidar Nómina</vs-button
                >
              </div>
            </div>

            <template v-slot:headerTitle></template>
            <div class="row px-5 mb-4 mt-4">
              <div
                class="table-responsive px-4 mt-3"
                v-if="listaLiquidacionPersona.length > 0"
              >
                <table
                  id="datatable_empleados"
                  class="table table-bordered table-responsive-md table-striped text-center"
                >
                  <thead>
                    <tr>
                      <th>Liquidar</th>
                      <th>Nombre</th>
                      <th>Identificación</th>
                      <th>Salario</th>
                      <th>Pagos adicionales</th>
                      <th>Deducciones</th>
                      <th>Total a pagar</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in listaLiquidacionPersona"
                      :key="item.dataId"
                    >
                      <td>
                        {{ item.liquidado == true ? "Liquidado" : "" }}
                        <b-form-checkbox
                          v-if="
                            item.liquidado == null || item.liquidado != true
                          "
                          @change="cambiarEstadoPersona(item, $event)"
                          :id="'checkbox-1' + item.persona.id"
                          v-model="item.estado"
                          :name="'checkbox-1' + item.persona.id"
                          value="0"
                          unchecked-value="-1"
                        >
                        </b-form-checkbox>
                      </td>
                      <td>
                        {{
                          item.persona.nombres +
                          " " +
                          (item.persona.segundoNombre != null
                            ? item.persona.segundoNombre
                            : "") +
                          " " +
                          item.persona.apellidos +
                          " " +
                          (item.persona.segundoApellido != null
                            ? item.persona.segundoApellido
                            : "")
                        }}
                      </td>
                      <td>{{ item.persona.numeroDocumento }}</td>
                      <td>
                        {{
                          item.persona.salario != null
                            ? item.persona.salario.toLocaleString("es-CO", {
                                style: "currency",
                                currency: "COP",
                              })
                            : (0).toLocaleString("es-CO", {
                                style: "currency",
                                currency: "COP",
                              })
                        }}
                      </td>
                      <td>
                        {{
                          item.totalIngresosAdicionales.toLocaleString(
                            "es-CO",
                            {
                              style: "currency",
                              currency: "COP",
                            }
                          )
                        }}
                      </td>
                      <td>
                        {{
                          item.totalDeducciones.toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        }}
                      </td>
                      <td>
                        {{
                          item.totalPago.toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        }}
                      </td>
                      <td>
                        <span class="pointer" v-if="item.estado != -1">
                          <!-- <i
                            @click="
                              $bvModal.show('my-modalDevengados');
                              usuarioSeleccionado = item;
                            "
                            class="fas fa-solid fa-plus px-1 text-success mr-2"
                          ></i>
                          <i
                            @click="
                              $bvModal.show('my-modalDeducciones');
                              usuarioSeleccionado = item;
                            "
                            class="fas fa-solid fa-minus px-1 text-red mr-2"
                          ></i> -->
                          <!-- <i @click="$router.push('departamentos_editar/' + item.id)" class="fas fa-solid fa-pen px-1 text-success mr-2"></i> -->
                          <i
                            @click="modalDetalle(item)"
                            class="fas fa-solid fa-eye px-1 text-success mr-2"
                          ></i>
                          <i
                            @click="
                              $router.push(
                                '/helex/liquidacioneditardetalle/' +
                                  id +
                                  '/' +
                                  item.persona.id
                              )
                            "
                            class="fas fa-solid fa-file px-1 text-success mr-2"
                          ></i>
                          <!-- <i @click="deleteRow(item.id)" class="fas fa-solid fa-trash px-1 text-danger"></i> -->
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>
      <!--<div class="text-right px-5 pb-4">
        <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Guardar</button>
        <button class="mx-1 btn px-4 py-2 btn-secondary" @click="$router.push('/helex/numeraciones')">Cancelar</button>
      </div>-->
    </form>
  </b-container>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import CardTable from "../../../../components/cardTable/CardTableV2.vue";
const Swal = require("sweetalert2");

export default {
  components: {
    CardTable,
  },
  name: "DataTable",
  data() {
    return {
      usuario: { rol: { nivel: 0 } },
      id: "",
      selected: null,
      item: {
        fechaInicio: null, //this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        fechaFin: null, //this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        CantidadEmpleados: 0.0,
        TotalSalarios: 0,
        TotalPagosAdicionales: 0, ///<-----
        TotalDeducciones: 0,
        TotalCostos: 0,
        TotalAPagar: 0,
        EmpresaId: "",
        SucursalId: "",
        Descripcion: "",
        Estado: 1,
        persona: { nombres: "", apellidos: "" },
      },
      ListEmpresas: [],
      ListSucursales: [],
      listaItems: [],
      listaEmpleados: [],
      nominaConfiguracion: {},
      listaDeducciones: [],
      listaDevengados: [],
      listaLiquidacionPersona: [],
      listaLiquidacionPersonaDetalle: [],
      nominaLiquidacionPersonaDetalle: [],
      nuevoItem: {},
      usuarioSeleccionado: null,
      porcenta: 0.75,
      porcenta2: 1.1,
      porcenta3: 1,
      valporcenta: 1,
      valporcenta2: 1,
      valporcenta3: 1,
      dataCards: [
        {
          title: "Empleados",
          value: () => this.empleadosActivos,
          iconName: "users",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
        },

        {
          title: "Salarios",
          // value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.salario), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
          value: () => this.salarioEmpleadosActivos,

          iconName: "money-bill-1",
          color: "rgba(71, 136, 255, 1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Pagos adicionales",
          // value: () =>
          //   this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalIngresosAdicionales), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
          value: () => this.pagosAdicionales,
          iconName: "hand-point-up",
          color: "rgba(71,136,255,1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Deducciones",
          // value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalDeducciones), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
          value: () => this.deducciones,
          iconName: "hand-point-down",
          color: "rgba(223, 18, 46, 1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Total a pagar",
          // value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalPago), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
          value: () => this.totalAPagar,
          iconName: "hand-holding-dollar",
          color: "rgba(71, 136, 255, 1)",
          tipo: "shield",
          animated: "bounce",
        },
        // {
        //   title: "Total Costo",
        //   value: () =>
        //   this.listaItems.filter(
        //       (tick) => tick.prioridad == 1
        //     ).length,
        //   iconName: "hand-point-up",
        //   color: "rgba(71, 136, 255, 1)",
        //   tipo: "shield",
        //   animated: "bounce",
        //   onActivate: () => {
        //     this.dataTableInstance.search("Baja").draw();
        //     console.log("Activated");
        //   },
        //   onDeactivate: () => {
        //     this.dataTableInstance.search("").draw();
        //     console.log("Deactivate");
        //   },
        // },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.item.EmpresaId = this.usuario.empresa.id;

    this.id = this.$route.params.id;
    await this.getData();
    await this.getEmpleados();
    await this.getNominaConfiguracion();
    await this.getDeducciones();
    await this.getDevengados();
    // await this.getEmpresas();
    // await this.getSucursalEmpresa();
    core.index();
    window.$("#datatable_empleados").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });

    window.$("#datatable_empleados2").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  computed: {
    empleadosActivos() {
      return this.listaLiquidacionPersona.filter((x) => x.estado == 0).length;
    },
    salarioEmpleadosActivos() {
      let empleados = this.listaLiquidacionPersona.filter((x) => x.estado == 0);

      return empleados
        .reduce((prev, curr) => prev + Number(curr.salario), 0)
        .toLocaleString("es-CO", { style: "currency", currency: "COP" });
    },
    pagosAdicionales() {
      let empleados = this.listaLiquidacionPersona.filter((x) => x.estado == 0);
      return empleados
        .reduce((prev, curr) => prev + Number(curr.totalIngresosAdicionales), 0)
        .toLocaleString("es-CO", { style: "currency", currency: "COP" });
    },
    deducciones() {
      let empleados = this.listaLiquidacionPersona.filter((x) => x.estado == 0);
      return empleados
        .reduce((prev, curr) => prev + Number(curr.totalDeducciones), 0)
        .toLocaleString("es-CO", { style: "currency", currency: "COP" });
    },
    totalAPagar() {
      let empleados = this.listaLiquidacionPersona.filter((x) => x.estado == 0);
      return empleados
        .reduce((prev, curr) => prev + Number(curr.totalPago), 0)
        .toLocaleString("es-CO", { style: "currency", currency: "COP" });
    },
  },
  methods: {
    async ActualizaTotalesNominaLiquidacion() {
      await this.$store.dispatch("hl_post", {
        path: "NominaLiquidacion/ActualizaTotalesNominaLiquidacion/" + this.id,
      });
    },
    async cambiarEstadoPersona(item, e) {
      // alert(item.persona.id);
      // alert(e);
      // console.log("item", item);
      let dataLiquidaPersona = {
        Id: item.id,
        liquidacionId: item.liquidacionId,
        PersonaId: item.persona.id,
        Estado: e,
        Salario: Number(item.persona.salario),
        totalIngresosAdicionales: Number(item.totalIngresosAdicionales),
        TotalDeducciones: Number(item.totalDeducciones),
        TotalPago: Number(item.totalPago),
        MetodoPago: Number(item.MetodoPago),
        DiasLiquidados: Number(item.diasLiquidados),
      };
      console.log(dataLiquidaPersona);
      await this.$store.dispatch("hl_post", {
        path: "NominaLiquidacion/NominaLiquidacionPersona",
        data: dataLiquidaPersona,
      });
      this.ActualizaTotalesNominaLiquidacion();
    },
    async liquidarNomina() {
      this.$isLoading(true);
      //crear lista con empleados activos

      let empleadosALiquidar = this.listaLiquidacionPersona
        .filter((x) => x.estado == 0)
        .map((y) => y);
      console.log("lista de empleados a liquidar", empleadosALiquidar);
      let path = "NominaLiquidacion/LiquidarPersonas/";
      await this.$store.dispatch("hl_post", {
        path: path,
        data: empleadosALiquidar,
      });
      // this.$isLoading(false);
      // await this.getData();
      // await this.getEmpleados();
      // this.ActualizaTotalesNominaLiquidacion();
      // this.$forceUpdate();
      this.$isLoading(false);
      Swal.fire("Listo!", "Liquidación procesada correctamente!.", "success");
    },
    async recalculaLiquida() {
      this.$isLoading(true);

      let path =
        "NominaLiquidacion/CrearNominaLiquidacionAutoEmpresa/" +
        this.item.tipoLiquidacion +
        "/" +
        this.item.empresaId +
        "/" +
        this.item.fechaInicio +
        "/" +
        this.item.fechaFin;
      await this.$store.dispatch("hl_post", {
        path: path,
      });
      this.$isLoading(false);
      await this.getData();
      await this.getEmpleados();
      this.ActualizaTotalesNominaLiquidacion();
      this.$forceUpdate();
    },
    async actualizaTotalesLiquida() {
      let salarios = this.listaLiquidacionPersona.reduce(
        (prev, curr) => prev + Number(curr.salario),
        0
      );
      console.log("salarios", salarios);

      let empleados = this.listaLiquidacionPersona.length;
      console.log(empleados);

      let pagosAdicionales = this.listaLiquidacionPersona.reduce(
        (prev, curr) => prev + Number(curr.totalIngresosAdicionales),
        0
      );
      console.log(pagosAdicionales);

      let deducciones = this.listaLiquidacionPersona.reduce(
        (prev, curr) => prev + Number(curr.totalDeducciones),
        0
      );
      console.log(deducciones);

      let totalAPagar = this.listaLiquidacionPersona.reduce(
        (prev, curr) => prev + Number(curr.totalPago),
        0
      );
      console.log(totalAPagar);

      let data = {
        Id: this.id,
        TotalSalarios: salarios,
        TotalPagosAdicionales: pagosAdicionales,
        TotalDeducciones: deducciones,
        TotalAPagar: totalAPagar,
        CantidadEmpleados: empleados,
      };
      await this.$store.dispatch("hl_post", {
        path: "NominaLiquidacion/NominaLiquidacion",
        data: data,
      });
    },

    async dataModalDetalle(it) {
      for await (let itemDetalle of it.nominaLiquidacionPersonaDetalle) {
        if (itemDetalle.tipoNovedadId == 2) {
          console.log("lista dev", itemDetalle.conceptoId);
          console.log("lista ", this.listaDevengados);

          let filtroDev = this.listaDevengados.filter(
            (x) => x.id == itemDetalle.conceptoId
          );

          console.log("lista dev", filtroDev);

          itemDetalle.concepto = filtroDev[0].descripcion;
        }
        if (itemDetalle.tipoNovedadId == 1) {
          console.log("listadeducciones-tt ", this.listaDeducciones);
          let filtroDed = this.listaDeducciones.filter(
            (x) => x.id == itemDetalle.conceptoId
          );
          itemDetalle.concepto = filtroDed[0].descripcion;
        }
      }
      this.nominaLiquidacionPersonaDetalle = it.nominaLiquidacionPersonaDetalle;
      console.log(this.nominaLiquidacionPersonaDetalle);
      this.usuarioSeleccionado = it;
      console.log("usuario seleccionado ", it);
    },
    async modalDetalle(it) {
      try {
        await this.dataModalDetalle(it);
        this.$bvModal.show("miModalDetalle");
      } catch (error) {
        console.log("error", error);
      }
    },

    async getDevengados() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Devengado/GetDevengados/",
        });
        this.listaDevengados = res;
        console.log("list devengados", this.listaDevengados);
      } catch (error) {
        this.listaDevengados = {};
        console.log("err", error);
      }
    },
    async getDeducciones() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Deduccion/GetDeducciones",
        });
        this.listaDeducciones = res;
        console.log("list DEDUCCIONES **************", this.listaDeducciones);
      } catch (error) {
        this.listaDeducciones = {};
        console.log("err", error);
      }
    },
    async getNominaConfiguracion() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "NominaConfiguracion/GetNominaConfiguracion/",
        });
        this.nominaConfiguracion = res;

        console.log(
          "list this.nominaConfiguracion ***************************",
          this.nominaConfiguracion
        );
      } catch (error) {
        this.nominaConfiguracion = {};
        console.log("err", error);
      }
    },

    async getSucursalEmpresa() {
      try {
        console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + this.usuario.empresa.id,
        });
        console.log("post sucursal... ", res);
        if (res.length > 0) {
          this.ListSucursales = res;
        } else {
          this.ListSucursales = [];
        }
      } catch (error) {
        this.ListSucursales = [];
        console.log("err", error);
      }
    },
    async getEmpleados() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/ListPersona/" + this.usuario.empresa.id,
        });
        this.listaEmpleados = res;

        console.log("list", this.listaEmpleados);
      } catch (error) {
        this.listaEmpleados = {};
        console.log("err", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "NominaLiquidacion/GetNominaLiquidacionId/" + this.id,
        });
        this.item = res;
        this.item.fechaInicio = this.$moment
          .tz(this.item.fechaInicio, "America/Costa_Rica")
          .format("YYYY-MM-DD");
        this.item.fechaFin = this.$moment
          .tz(this.item.fechaFin, "America/Costa_Rica")
          .format("YYYY-MM-DD");
        console.log("////////*////////////*", this.item);
        //obtenemos lista de GetNominaLiquidacionPersonas
        //let str =
        let resLiquidaPersona = await this.$store.dispatch("hl_get", {
          path: "NominaLiquidacion/GetNominaLiquidacionPersonas/" + this.id,
        });
        this.listaLiquidacionPersona = resLiquidaPersona;
        console.log(
          "this.listaLiquidacionPersona******************> ",
          this.listaLiquidacionPersona
        );
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
  },
};
</script>
<style scoped>
th {
  position: sticky;
  top: 0px; /* 0px if you don't have a navbar, but something is required */
  background: #0c1a30;
}
</style>
